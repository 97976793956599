<template>
	<v-layout class="wellcome">
		<v-flex>
			<h1 class="pa-3 display-1 text-center grey--text">{{ $t('puiwellcomepanel.header') }}</h1>
			<div class="pa-3 text-center">
				<div class="novedadesdiv">
					<h1 class="pa-1 text-left" style="color: #02449a;">{{ $t('form.novedades') }}</h1>
				</div>
				<div class="novedadesdiv" style="height:800px; overflow-y: auto;">
					<div v-for="(novedad, index2) in novedades" :key="index2">
						<v-layout>
							<v-flex>
								<h3 class="pa-1 text-left novedadesdivtitulo">
									<span class="fal fa-thumbtack theme--light fa-rotate-270"></span
									><span class="black--text"> {{ getDiaRecogida(novedad.fecpublicacion) }}</span> {{ novedad.titulo }}
								</h3>
							</v-flex>
						</v-layout>
						<v-layout>
							<v-flex>
								<h3 class="pa-1 text-left font-weight-regular novedadesdivdescripcion" v-html="novedad.descripcion"></h3>
							</v-flex>
						</v-layout>
						<v-layout style="height:10px;"> </v-layout>
					</div>
				</div>
			</div>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'AppWelcomePanel',
	data: () => ({ novedadesUrlController: '/novedades/listado', novedades: {} }),
	methods: {
		getNovedades() {
			this.$puiRequests.getRequest(
				this.novedadesUrlController,
				null,
				response => {
					if (response.data) {
						this.novedades = response.data;
					}
				},
				error => {
					const params = {};
					params.error = error;
					this.$store.dispatch('puiRequestShowServerError', params);
				}
			);
		},
		getDiaRecogida(value) {
			return value ? this.$dateTimeUtils.formatDate(value, this.$store.getters.dateFormat) : '';
		}
	},
	created() {
		this.getNovedades();
	}
};
</script>

<style lang="postcss" scoped>
.wellcome {
	padding-top: 5% !important;
}
.novedadesdiv {
	margin: auto;
	width: 80%;
}
.novedadesdivtitulo {
	color: #02449a;
	background-color: #f5f7f7;
}
.novedadesdivdescripcion {
	background-color: #e9eced;
}
</style>
